import React from 'react';
import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material';

const PackageCard = ({ pkg }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        height="200"
        image={pkg.imageUrl}
        alt={pkg.name}
      />
      <CardContent>
        <Typography variant="h6">{pkg.name}</Typography>
        <Typography variant="body2">{pkg.short_description}</Typography>
        <Typography variant="body2">Price: ₹{pkg.price_per_person}</Typography>
        <Typography variant="body2">{pkg.number_of_days} days, {pkg.number_of_nights} nights</Typography>
        <Typography variant="body2">Pickup: {pkg.Pickup_location}</Typography>
        <Button variant="contained" color="primary" style={{ marginTop: '1rem' }}>
          Book Now
        </Button>
      </CardContent>
    </Card>
  );
};

export default PackageCard;
