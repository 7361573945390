import React from 'react';
import { Typography } from '@mui/material';

import { Grid } from '@mui/material';


const Section = ({ title, items, renderItem }) => {
  return (
    <>
      <Typography variant="h4" gutterBottom>{title}</Typography>
      <Grid container spacing={4}>
        {items.map(renderItem)}
      </Grid>
    </>
  );
};

export default Section;
