import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import axios from 'axios';
import { Grid } from '@mui/material';


import CategoryCard from '../Components/CategoryCard';
import DestinationCard from '../Components/DestinationCard';
import PackageCard from '../Components/PackageCard';
import Section from '../Components/Section';

const token = 'YOUR_BEARER_TOKEN';
const apiUrl = 'https://admin.tourismooze.com/api/public/home';
const imageBaseUrl = 'https://admin.tourismooze.com/storage/';

const HomePage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.post(apiUrl, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
    .then((response) => {
      setData(response.data.data);
    })
    .catch((error) => {
      console.error('Error fetching the data:', error);
    });
  }, []);

  if (!data) return <p>Loading...</p>;

  return (
    <Container>
      {/* Categories Section */}
      <Section
        title="Categories"
        items={data.categories}
        renderItem={(category) => (
          <Grid item xs={12} sm={6} md={3} key={category.id}>
            <CategoryCard category={{ ...category, imageUrl: `${imageBaseUrl}${category.image_path}` }} />
          </Grid>
        )}
      />

      {/* Trending Destinations Section */}
      <Section
        title="Trending Destinations"
        items={data.trending_destinations}
        renderItem={(destination) => (
          <Grid item xs={12} sm={6} md={4} key={destination.id}>
            <DestinationCard destination={{ ...destination, imageUrl: `${imageBaseUrl}${destination.destination_image}` }} />
          </Grid>
        )}
      />

      {/* Popular Packages Section */}
      <Section
        title="Popular Packages"
        items={data.popular_packages}
        renderItem={(pkg) => (
          <Grid item xs={12} sm={6} md={4} key={pkg.id}>
            <PackageCard pkg={{ ...pkg, imageUrl: `${imageBaseUrl}${pkg.package_image}` }} />
          </Grid>
        )}
      />
    </Container>
  );
};

export default HomePage;
