import React from 'react';
import { CssBaseline } from '@mui/material';
import HomePage from './Pages/HomePage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const App = () => {
  return (
    <>
      <CssBaseline />
      <HomePage />
    </>
  );
};

export default App;
